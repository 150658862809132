export default {
    zhCN: {
        query: '查询',
        editing_position: '编辑岗位',
        show_theme_editor: '显示/隐藏主题编辑器',
        change_password: '修改密码',
        old_password: '旧密码',
        new_password: '新密码',
        confirm_password: '确认密码',
        cipher: '密码',
        change_new_password: '请填写新密码',
        button: '按钮',
        about: '关于',
        back: '返回',
        go: '确定',
        home: '首页',
        toggle_dark: '切换深色模式',
        toggle_langs: '切换语言',
        confirm: '确定',
        modify: '修改',
        edit: '编辑',
        new: '新增',
        cancel: '取消',
        close: '关闭',
        search: '搜索',
        reset: '重置',
        delete: '删除',
        add: '新增',
        collapse: '收起',
        expand: '展开',
        download_template: '下载模板',
        batch_import: '批量导入',
        overlay_import: '覆盖导入',
        no_file_selected: '未选择文件',
        no_import_file_selected: '未选择导入文件',
        import: '导入',
        export: '导出',
        export_fail: '导入失败',
        browse: '浏览',
        batch_clear_sap_code: '批量清空SAP Code',
        batch_clear_special_purchase_cycle: '批量清空特殊采购周期',
        detailed: '明细',
        download: '下载',
        toView: '查看',
        toViews: '查看图片',
        uploadAttachments: '上传附件',
        selectFile: '选择文件',
        fillingInstructions: '填写说明',
        release: '发布',
        save: '保存',
        tips: '提示',
        send_back: '退回',
        examine: '审核',
        create_sap: '创建SAP',
        update: '更新',
        updateTime: '更新有效期',
        ilterOptionsupdate: '更新',
        void: '作废',
        more: '更多',
        refresh: '刷新',
        submit_sap: '提交SAP',
        export_sap: '导出提交SAP数据',
        export_rebate_detail: '导出返利明细',
        export_rebate: '导出返利汇总',
        export_rebate_pay: '导出返利支付',
        submit: '提交',
        save_button: '保存并新建',
        insufficient: '账户资金不足是否提交',
        yes: '是',
        deny: '否',
        staging: '暂存',
        return: '退回',
        table: '序号',
        select: '选择',
        all: '共',
        enter: '请输入',
        selects: '请选择',
        please_search: '请点击查询',
        modify_ladder: '修改阶梯',
        pass: '通过',
        approved: '批准',
        serial_number: '序号',
        individual: '个',
        upload_progress: '上传进度',
        port_choose: '港口选择',
        request_code: '请勾选配件',
        request_discuss: '请选择供应商',
        request_dt: '请勾选DT',
        request_thing: '请勾选物料小类',
        accessories_information: '配件信息',
        select_accessories: '选择配件',
        binding: '绑定',
        tick_data: '请勾选数据',
        invalid: '作废',
        confirm_number: '确认箱单号:',
        empty_data: '清空生成数据',
        variance_details: '差异明细',
        daily_record: '日志',
        operation_type: '操作类型',
        field_name: '字段名',
        edit_role: '编辑角色',
        user_role: '选择角色',
        assignment_role: '角色',
        post: '岗位',
        please_settled: '请选择要结算的配件',
        not_sent: '未发送',
        sending: '发送中',
        has_been_sent: '已发送',
        fail_in_send: '发送失败',
        express_import: '快递导入',
        reset_password: '重置密码',
        display_or_not: '是否显示',
        password_reset: '密码重置',
        new_item: '新建项目',
        add_item: '新增项目',
        fleet_sales_import: '大客户订单导入',
        finance_import: '信贷订单导入',
        copy: '复制',
        change_history: '日志',
        generate_statement: '生成对账单',
        review: '审批',
        the_pass: '通过',
        the_approved: '通过',
        reject: '拒绝',
        upload: '上传',
        upload_files: '上传文件',
        recalculate: '重新计算',
        approval_history: '审批历史',
        appeal_files: '附件',
        detail: '详情',
        confirm_fallback: '确认回退',
        confirm_reject: '确认拒绝',
        appeal: '申诉提交',
        draft_appeal: '申诉草稿',
        clear_confirm_states: '清除确认状态',
        batch_pass: '批量通过',
        calculate: '计算',
        add_line: '增加行(VIN)',
        dealer_info: '经销商信息',
        info: '信息',
        batch_edit: '批量修改',
        batch_submit: '批量提交',
        video_download: '下载视频',
        documen_download: '下载操作手册',
        quarterly_sales_target_import: '季度销售目标导入',
        other_sales_target_import: '其他销售目标导入',
        batch_approval: '批量审核',
        export_detail: '导出详情',
        export_summary: '导出总结',
        export_vin: '导出VIN',
        batch_delete: '批量删除',
        result: '结果',
    },
    enUS: {
        editing_position: 'Editing position',
        query: 'Query',
        old_password: 'Old password',
        new_password: 'New password',
        confirm_password: 'Confirm Password',
        change_password: 'Change Password',
        cipher: 'cipher',
        change_new_password: 'Please fill in the new password',
        show_theme_editor: 'Show/Hide Theme Editor',
        button: 'Button',
        about: 'About',
        back: 'Back',
        go: 'GO',
        home: 'Home',
        toggle_dark: 'Toggle dark mode',
        toggle_langs: 'Change languages',
        confirm: 'Confirm',
        modify: 'Edit',
        edit: 'Edit',
        new: 'New',
        cancel: 'Cancel',
        close: 'Closed',
        search: 'Search',
        reset: 'Reset',
        delete: 'Delete',
        add: 'Add',
        collapse: 'Collapse',
        expand: 'Expand',
        download_template: 'Download Template',
        batch_import: 'Batch Import',
        overlay_import: 'Overlay Import',
        import: 'Import',
        export: 'Export',
        export_fail: 'Export Fail',
        browse: 'Browse',
        no_file_selected: 'No file selected',
        no_import_file_selected: 'No import file selected',
        batch_clear_sap_code: 'Batch clear SAP code',
        batch_clear_special_purchase_cycle: 'Batch clear special purchase cycle',
        detailed: 'detailed',
        download: 'Download',
        toView: 'View',
        toViews: 'Check',
        uploadAttachments: 'Upload',
        selectFile: 'Select File',
        fillingInstructions: 'Filling Instructions',
        release: 'Release',
        save: 'Save',
        send_back: 'Return',
        examine: 'examine',
        updateTime: 'Update validity period',
        update: 'Update',
        void: 'Void',
        more: 'More',
        refresh: 'Refresh',
        submit_sap: 'Submit SAP',
        export_sap: 'Export SAP',
        export_rebate_detail: 'Export Rebate Detail',
        export_rebate: 'Export Rebate Summary',
        export_rebate_pay: 'Export Rebate Pay',
        submit: 'Submit',
        save_button: 'Save and New',
        insufficient: 'Whether to submit if the account fund is insufficient',
        yes: 'Yes',
        deny: 'Deny',
        staging: 'Save and Close',
        return: 'Return',
        table: 'No',
        select: 'Choose',
        all: 'All',
        create_sap: 'CreateSap',
        enter: 'Please Input',
        selects: 'Please Select',
        please_search: 'Please click to search',
        modify_ladder: 'Modify ladder',
        pass: 'Pass through',
        approved: 'Approved',
        serial_number: 'No.',
        individual: 'Individual',
        upload_progress: 'Upload Progress',
        port_choose: 'Port choose',
        request_code: 'Please check accessories',
        request_discuss: 'Please select a supplier',
        request_dt: 'Please check DT',
        request_thing: 'Please check the material category',
        translate: 'Only letters',
        accessories_information: 'Accessories information',
        select_accessories: 'Select accessories',
        binding: 'Binding',
        tick_data: 'Please check the data',
        invalid: 'Invalid',
        empty_data: 'Clear generated data',
        confirm_number: 'Confirm packing list number',
        variance_details: 'Variance Details',
        daily_record: 'LOG',
        operation_type: 'Operation type',
        field_name: 'Field Name',
        edit_role: 'Edit Role',
        user_role: 'User Role',
        assignment_role: 'Role',
        post: 'Post',
        please_settled: 'Please select the part to be settled',
        not_sent: 'Not sent',
        sending: 'Sending',
        has_been_sent: 'Has been sent',
        fail_in_send: 'Fail in send',
        express_import: 'Express import',
        reset_password: 'ResetPWD',
        display_or_not: 'Display Or Not',
        password_reset: 'Password Reset',
        new_item: 'New Item',
        add_item: 'Add Item',
        fleet_sales_import: 'Fleet Sales Import',
        finance_import: 'FDA Import',
        copy: 'Copy',
        change_history: 'LOG',
        generate_statement: 'Generate  Statement',
        review: 'Review',
        the_pass: 'Pass',
        the_approved: 'Approved',
        reject: 'Reject',
        upload: 'Upload',
        upload_files: 'Upload Files',
        recalculate: 'Recalculate',
        approval_history: 'Approval History',
        appeal_files: 'Attachments',
        detail: 'Detail',
        confirm_fallback: 'Confirm Fallback',
        confirm_reject: 'Confirmation of rejection',
        appeal: 'Appeal Submit',
        draft_appeal: 'Appeal Draft',
        clear_confirm_states: 'Clear Confirm Status',
        batch_pass: 'Batch Pass',
        calculate: 'Calculate',
        add_line: 'Add VIN',
        dealer_info: 'Dealer Info',
        info: 'Info',
        batch_edit: 'Batch Edit',
        batch_submit: 'Batch Submit',
        video_download: 'Download Video ',
        documen_download: 'Download Manual',
        quarterly_sales_target_import: 'Quarterly Sales Target Import',
        other_sales_target_import: 'Other Sales Target Import',
        batch_approval: 'Batch Approval',
        export_detail: 'Export Detail',
        export_summary: 'Export Summary',
        export_vin: 'Export VIN',
        batch_delete: 'Batch Delete',
        result: 'Result',
    },
};
